@import "../../custom";

.ocean-container {
  background-color: $light-orange;
}

.ocean {
  height: 80px; /* change the height of the waves here */
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.wave-1 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%238ac8e3'/%3E%3C/svg%3E") repeat-x;
  position: absolute;
  width: 200%;
  height: 100%;
  transform: translateX(-23%);
}

.wave-2 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003046'/%3E%3C/svg%3E") repeat-x;
  position: absolute;
  width: 200%;
  height: 100%;
  transform: translateX(-30%);
}

.wave-3 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23069dba'/%3E%3C/svg%3E") repeat-x;
  position: absolute;
  width: 200%;
  height: 100%;
  transform: translateX(-8%);
  opacity: 0.8;
}