@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import "../../custom";

.home-container {
  padding: 1rem;
  background-color: $light-orange;
  @include media-breakpoint-up(lg) {
    padding: 0 10rem;
  }
}

.start-new-memo {
  border-radius: 50% !important;
  bottom: 10rem;
  line-height: 0;
  height: 5rem;
  position: absolute;
  right: 1rem;
  width: 5rem;
  z-index: 100;

  @include media-breakpoint-up(lg) {
    right: 10rem;

  }
}

.pen {
  font-size: 2rem;
}