$lightest-blue: #b9bfc7;
$light-blue: #8ac8e3;
$blue: #069dba;
$dark-blue: #0087a2;
$darkest-blue: #003046;
$yellow: #fdb719;
$orange: #fc8516;
$light-orange: #ffead8;

$primary: $yellow !default;
$secondary: $darkest-blue !default;

$dark: #282c34;
$light: #b9bfc7;

$theme-colors: (
    "primary": $primary,
    "primary-lighter": lighten($primary, 20%),
    "secondary": $secondary,
    "orange": $orange,
    "dark": $dark,
    "light": $light,
);

@import '~bootstrap/scss/bootstrap.scss';