@import "../../custom";

.empty-state-container {
  color: $light;
  text-align: center;
  padding: 1rem;
  overflow-y: auto;
  @media screen and (max-height: 840px){
    height: 50vh
  }
}

.opened-bottle {
  max-height: 18rem;
  rotate: 45deg;
  filter: invert(93%) sepia(7%) saturate(294%) hue-rotate(174deg) brightness(84%) contrast(85%) //https://codepen.io/sosuke/pen/Pjoqqp
}

.cork {
  position: relative;
  left: 3rem;
  top: 7rem;
  max-height: 3rem;
  rotate: -20deg;
  filter: invert(93%) sepia(7%) saturate(294%) hue-rotate(174deg) brightness(84%) contrast(85%) //https://codepen.io/sosuke/pen/Pjoqqp
}