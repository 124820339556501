.sealed-bottle {
  max-height: 80px;
  animation: bottleFloatLess 3s linear infinite;
}

.sealed-bottle-container {
  height: 5rem;
  width: 5rem;
  display: block;
  position: relative;
  cursor: pointer;
  transition: transform 300ms;

  &:hover {
    transform: scale(1.3);
    transition: 200ms;
  }
}

@keyframes bottleFloatLess {
  0% {
    transform: translateY(0) rotate(0);
  }
  25% {
    transform: translateY(0) rotate(-2deg);
  }
  65% {
    transform: translateY(2px) rotate(4deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}