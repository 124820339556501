@import "../../custom";
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $dark-blue;
}

.footer-content {
  padding: 0 1rem;
  @include media-breakpoint-up(lg) {
    padding: 1rem 10rem;
  }
}
