@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.editor {
  height: 50vh;
  overflow-y: auto;
  @include media-breakpoint-down(md) {
    height: 45vh;
  }
}

.editor-container {
  padding: 0 1rem;
  @include media-breakpoint-up(lg) {
    padding: 1rem 10rem;
  }
}