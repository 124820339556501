@use "../../custom";

.section-down {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 8rem;
  top: 16rem;
  z-index: 6;
}

@keyframes moveWater {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-33.33333333%);
  }
}

.water-big-wrapper {
  position: absolute;
  top: 3vh;
  height: 5rem;
  width: 114px;
  z-index: 6;
  overflow: hidden;
  border-radius: 30% 30% 50% 50%;
}

.water-big-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 114px;
  width: 348px;
  display: block;
  z-index: 6;
  animation: moveWater 5s linear infinite;
}

.water-big {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 116px;
  display: block;
  z-index: 1;
}

.water-big.snd {
  left: 116px;
}

.water-big.trd {
  left: 232px;
}

.water-big span {
  position: absolute;
  left: 0;
  top: 20px;
  height: 50px;
  width: 116px;
  display: block;
  z-index: 1;
  background-color: custom.$light-blue;
}

.water-big span:nth-child(2) {
  left: -2px;
  top: -20px;
  height: 40px;
  width: 40px;
  z-index: 2;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid;
  border-color: transparent custom.$dark-blue custom.$dark-blue transparent;
  transform: rotate(45deg);
}

.water-big span:nth-child(3) {
  left: 37px;
  top: -20px;
  height: 40px;
  width: 40px;
  z-index: 2;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid;
  border-color: transparent #023047 #023047 transparent;
  transform: rotate(45deg);
}

.water-big span:nth-child(4) {
  left: 75px;
  top: -20px;
  height: 40px;
  width: 40px;
  z-index: 2;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #023047;
  border-color: transparent #023047 #023047 transparent;
  transform: rotate(45deg);
}

.water-big span:nth-child(5) {
  left: 8px;
  top: 14px;
  height: 60px;
  width: 60px;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: scale(0.45, 1) rotate(-45deg);
}

.water-big span:nth-child(6) {
  left: 46px;
  top: 14px;
  height: 60px;
  width: 60px;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: scale(0.45, 1) rotate(-45deg);
}

.water-big span:nth-child(7) {
  left: 84px;
  top: 14px;
  height: 60px;
  width: 60px;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: scale(0.45, 1) rotate(-45deg);
}

.water-big span:nth-child(8) {
  left: -30px;
  top: 14px;
  height: 60px;
  width: 60px;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: scale(0.45, 1) rotate(-45deg);
}

.water-big span:nth-child(9) {
  left: 5px;
  top: 15px;
  height: 15px;
  width: 10px;
  transform: rotate(-55deg);
}

.water-big span:nth-child(10) {
  left: 43px;
  top: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(-55deg);
}

.water-big span:nth-child(11) {
  left: 81px;
  top: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(-55deg);
}

.water-big span:nth-child(12) {
  left: 23px;
  top: 13px;
  height: 17px;
  width: 10px;
  transform: rotate(55deg);
}

.water-big span:nth-child(13) {
  left: 62px;
  top: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(55deg);
}

.water-big span:nth-child(14) {
  left: 100px;
  top: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(55deg);
}

.bottle {
  animation: bottleFloat 2s linear infinite;
}

.bottle img {
  position: relative;
  width: 30px;
  transform: rotate(45deg);
}

@keyframes bottleFloat {
  0% {
    transform: translateY(0) rotate(0);
  }
  25% {
    transform: translateY(0) rotate(-10deg);
  }
  40% {
    transform: translateY(4px) rotate(-10deg);
  }
  85% {
    transform: translateY(1px) rotate(10deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
